import Link from 'next/link';
import { useEffect, useState } from 'react';

import Loader from 'atoms/Loader';
import Header from 'components/Layout/Common/Header';
import generateMetabaseDashboardSignedUrl from 'services/matebase.service';

const fetchMetabaseDashboardSignedUrl = async ({
  params = {},
  query = {},
  setMetabaseQuestionUrl
}) => {
  const { data, status: apiStatus } = await generateMetabaseDashboardSignedUrl({
    query,
    params
  });
  const { status, entity } = data || {};
  if (apiStatus && status) {
    setMetabaseQuestionUrl(entity.url);
  }
};

const Breadcrumb = ({ setDashboard, title }) => (
  <div className='mb-2'>
    <Link
      href='/'
      className='text-gray-500'
    >
      Home
    </Link>
    <i className='fa fa-chevron-right breadcrumb-arrow mx-1'></i>
    <label
      className='text-gray-700 cursor-pointer'
      onClick={() => setDashboard(null)}
    >
      Dashboards
    </label>
    <i className='fa fa-chevron-right breadcrumb-arrow mx-1'></i>
    <label className='text-gray-700 cursor-pointer'>{title}</label>
  </div>
);

const MetabaseDashboard = ({
  dashboard: { params = {}, title = '', queryParameters = {} },
  hideBreadCrumb = false,
  setDashboard
}) => {
  const [loading, setLoading] = useState(true);
  const [metabaseQuestionUrl, setMetabaseQuestionUrl] = useState();

  useEffect(() => {
    fetchMetabaseDashboardSignedUrl({
      params,
      query: queryParameters,
      setMetabaseQuestionUrl
    });
  }, []);

  const finishLoading = () => {
    setLoading(false);
  };

  return (
    <>
      <Header title={title}></Header>
      <div className='page-content pb-0'>
        {!hideBreadCrumb && <Breadcrumb {...{ setDashboard, title }} />}
        {loading && <Loader />}
        <iframe
          className='dashboard-iframe'
          src={metabaseQuestionUrl}
          frameBorder='0'
          allowtransparency='true'
          onLoad={finishLoading}
        />
      </div>
    </>
  );
};

export default MetabaseDashboard;
